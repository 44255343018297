.blitester {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 68em;
  margin: 150px auto 100px auto;
}

.blitester_mobile {
  padding: 0 3rem 0 0;

  img {
    max-width: 306px;
  }
}

.blitester_content {
  padding: 0;

  h1 {
    font-size: 56px;
    line-height: 76px;
    font-family: 'Avenir Heavy';
    margin: 0;
    padding: 0;
    color: #2E2E55;
  }

  p {
    font-family: 'Avenir LT Std 55 Roman';
    color: #3D486B;
    font-size: 18px;
    line-height: 26px;
    margin-bottom: 50px;
    margin-top: 27px;
  }

  .app_link {
    display: flex;

    a {
      display: inline-block;
      vertical-align: top;
      margin-right: 16px;
      max-width: 150px;
    }

  }
}

.store-images {
  display: flex;
  align-items: center;
  justify-content: start;
  margin-top: 35px;

  a {
    display: inline-block;
    vertical-align: top;
    margin-right: 16px;
    max-width: 150px;
  }
}

.tasteful-block {
  .home-sustainable-left {
    width: 45em;
    padding-right: 5em;

    h4 {
      font-size: 45px;
      line-height: 60px;
      margin-bottom: 22px;
    }

    img {
      display: none;
    }

    p {
      font-size: 18.75px;
      line-height: 26.25px;
    }
  }
}

@media only screen and (max-width: 992px) and (min-width: 768px) {
  .blitester {
    max-width: 46em;
    margin: 100px auto 70px auto;
  }
}

@media only screen and (max-width: 767px) {
  .blitester {
    max-width: 86%;
    margin: 90px auto 60px auto;
  }

  .blitester_mobile {
    display: none;
  }

  .blitester_content {
    h1 {
      font-size: 26px;
      line-height: 36px;
    }

    p {
      font-size: 14px;
      line-height: 19px;
      margin-bottom: 30px;
      margin-top: 15px;
    }
  }

  .tasteful-block {
    display: block !important;
    padding-top: 50px !important;
    padding-bottom: 30px !important;

    .home-sustainable-inner {
      max-width: 86%;

      .home-sustainable {
        display: block;

        .home-sustainable-left {
          width: 100%;
          padding-right: 0;
          text-align: center;

          h4 {
            width: 100%;
            font-size: 22px;
            line-height: 30px;
            margin-bottom: 15px;
            text-align: center;
          }

          img {
            max-width: 80%;
            height: auto;
            display: inline-block;
          }

          p {
            font-size: 14px;
            line-height: 21px;
            margin-top: 20px;
            text-align: left;
          }
        }

        .home-sustainable-right {
          width: 100%;

          img {
            display: none;
          }
        }
      }
    }
  }

  .home-newTestRequest {
    width: 86%;
  }
}

@media only screen and (max-width: 767px) and (min-width: 568px) {
  .newTestRequest__texts {
    max-width: 34em;
  }
}

@media only screen and (max-width: 567px) and (min-width: 420px) {
  .newTestRequest__texts {
    max-width: 34em;
  }
}