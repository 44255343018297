.registration-form {
    display: flex;
    flex-direction: column;
    margin: 193px auto 196px auto;
    max-width: 40em;
    align-items: center;

    h1 {
        font-family: 'Avenir LT Std 65 Black';
        color: #3D486B;
        font-size: 37px;
        margin-bottom: 36px;
    }
}

.registration-footer-wrapper {
    display: flex;
    align-items: flex-end;
    justify-content: center;
}

.registration-form__footerText {
    max-width: 100%;
    margin: 0 auto;
    font-family: 'Avenir LT Std 55 Roman';
    color: #3D486B;
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 25px;
}

.form-footerText__link {
    color: #5959BE;
}

@media only screen and (max-width: 767px) {
    .registration-form {
        display: block;
        margin: 70px auto 50px auto;
        max-width: 86%;

        h1 {
            font-size: 25px;
            line-height: 30px;
            margin-bottom: 25px;
        }
    }

    .registration-form__footerText {
        font-size: 14px;
        line-height: 18px;
        margin-bottom: 20px;
    }

    .signup-button {
        width: 100% !important;
    }
}