.blogs-block {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 60em;
    margin: 108px auto 150px auto;
    padding-top: 108px;

    ul {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        flex-wrap: wrap;

        li {
            display: inline-block;
            padding-left: 13px;
            padding-right: 13px;
            padding-bottom: 55px;
            width: 33.33%;
            box-sizing: border-box;

            .blog {
                background-color: #ffffff;
                border-radius: 15px;
                display: flex;
                justify-content: center;
                align-items: start;
                flex-wrap: wrap;
                width: 100%;
                text-align: center;
                padding: 15px 16px 27px 16px;
                box-sizing: border-box;

                .image {
                    img {
                        max-width: 100%;
                        height: auto;
                    }
                }

                .blog-date {
                    width: 100%;
                    padding-top: 15px;
                    display: block;

                    .trends {
                        float: left;
                        font-size: 10px;
                        line-height: 14px;
                        color: #67B5B0;
                    }

                    .date {
                        float: right;
                        font-size: 10px;
                        line-height: 14px;
                        color: #3D486B;
                    }
                }

                .name {
                    width: 100%;
                    text-align: left;
                    float: left;
                    padding: 12px 0 15px 0;
                    font-size: 22.5px;
                    line-height: 30px;
                    color: #3D486B;
                    font-family: 'Avenir Heavy';

                    a {
                        color: #3D486B;
                        font-family: 'Avenir Heavy';
                    }
                }

                .text {
                    width: 100%;
                    text-align: left;
                    float: left;
                    font-size: 13.5px;
                    line-height: 22px;
                    color: #3D486B;
                }
            }
        }
    }
}

@media only screen and (max-width: 1280px) {
    .blogs-block {
        padding-top: 70px;
    }
}

@media only screen and (max-width: 992px) {
    .blogs-block {
        padding-top: 0px;

        ul {
            li {
                padding-bottom: 30px;
            }
        }
    }
}

@media only screen and (max-width: 767px) {
    .blogs-block {
        padding-top: 0px;

        ul {
            li {
                padding-bottom: 30px;
                width: 50%;
            }
        }
    }
}

@media only screen and (max-width: 539px) {
    .blogs-block {
        padding-top: 0px;
        margin: 93px auto 21px auto;

        ul {
            li {
                padding-left: 26.25px;
                padding-right: 26.25px;
                padding-bottom: 22.5px;
                width: 100%;

                .blog {
                    padding: 10px 12px 18px 12px;

                    .name {
                        font-size: 14.25px;
                        line-height: 19.5px;
                        padding: 9px 0 13.5px 0;
                    }

                    .blog-date {
                        padding-top: 8.25px;
                    }

                    .text {
                        font-size: 10.5px;
                        line-height: 15px;
                    }
                }
            }
        }
    }
}