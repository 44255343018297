.header-main {
  position: fixed;
  z-index: 1000;
  top: 0;
  width: 100%;
  background-color: #f7f7ff;

  .header-container {
    display: flex;
    margin: 0 auto;
    padding-top: 42px;
    padding-bottom: 15px;
    max-width: 72em;
    height: 43px;
    justify-content: space-between;
    align-items: center;

    .logo {
      height: 42px;
      margin-right: 32px;
    }

    .toggle_header {
      display: flex;
    }

    .navigation-block {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    li {
      cursor: pointer;
      list-style: none;
      color: #727c9b;
      font-weight: normal;
      font-size: 16.5px;
      line-height: 20px;
      margin-right: 15px;

      a {
        font-family: 'Avenir LT Std 55 Roman';
        color: #3D486B;
        letter-spacing: 0.8px;

        &.activeRoute {
          font-family: 'Avenir Medium';
          position: relative;

          &::after {
            content: "";
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            -webkit-border-radius: 2px;
            -moz-border-radius: 2px;
            border-radius: 2px;
            border-bottom: 4px solid #F7DDBC;
          }
        }
      }

      &.right-border {
        border-right: 2px solid #E2E3E8;
        min-height: 26px;
        margin-top: 0px;
        transform: rotate(25deg);
      }
    }

    .ctYJfV {
      display: block;
      text-align: center;
    }

    .gmeFWi {
      display: none;
      text-align: center;
    }

    .navbar-button-block {
      display: flex;
      height: 43px;
      align-items: center;

      li {
        margin-right: 0;
        margin-left: 37px;


        .button-with-border {
          display: inline-block;
          width: 90px;
          height: 43px;
          justify-content: center;
          align-items: center;
          border-radius: 7px;
          color: #3D486B;
          background-color: #F0F0F5;
          font-family: 'Avenir Heavy' !important;
          text-transform: none;
          font-weight: normal;
          font-size: 16.5px;
          line-height: 20px;

          &.book {
            background-color: #4AB7B2;
            color: #ffffff;
            width: 150px;
          }
        }

        &.book-btn {
          margin-left: 23px;
        }
      }
    }

    .small-menu-classname {
      ul {
        position: fixed;
        display: flex;
        flex-direction: column;
        background-color: #EEF2FA;
        z-index: 10;
        right: 0px;
        padding: 50px 40px 50px;
        border-radius: 0;
        animation: navbarAnimation 0.8s;
        height: calc(100% - 66px);
        width: 223px;
        text-align: right;
        box-sizing: border-box;
        margin-top: 66px;
        top: 0;

        li {
          margin-top: 0;
          margin-right: 0 !important;
          margin-left: 0 !important;
          margin-bottom: 20px;
          text-align: right;
          display: block;
          width: 100%;

          a {
            font-family: 'Avenir LT Std 55 Roman' !important;
            font-size: 16px;
            display: block;
            border-bottom: none;
            padding-bottom: 2px;

            &.activeRoute {
              font-family: 'Avenir Medium' !important;
              font-size: 16px;
              display: inline-block;
              border-bottom: none;
            }

            &.button-with-border {
              font-family: 'Avenir Heavy' !important;
              background-color: #ffffff;
              width: 100%;
              text-align: center;
              height: 42px;
              line-height: 42px;
            }
          }
        }
      }

      .navbar-button-block {
        display: block;
        text-align: right;
        height: auto;
        width: 100%;

        li {
          &:first-child {
            display: none;
          }
        }
      }

      .button-with-border.book {
        width: 100%!important;
        background-color: #ffffff!important;
        color: #3D486B!important;
      }
    }

  }
}

@media only screen and (max-width: 1200px) and (min-width: 992px) {
  .header-main {
    .header-container {
      max-width: 60em;
    }
  }
}


@media only screen and (max-width: 992px) {
  .header-main {
    .header-container {
      max-width: 86%;
      padding-top: 20px;
      padding-bottom: 20px;

      .ctYJfV {
        display: none;
      }

      .gmeFWi {
        display: block;
      }

      li {
        font-size: 17px;
      }

      .navbar-button-block {
        flex-direction: column;
      }

      .responsive-navbar-open {
        cursor: pointer;
      }

      .responsive-navbar-close {
        font-size: 14px;
        padding: 0 15px;
        background-color: #ffffff;
        width: 223px;
        margin-top: 0;
        height: 66px;
        line-height: 66px;
        text-align: left;
        animation: navbarAnimation 0.8s;
        box-sizing: border-box;
        position: absolute;
        right: 0;
        top: 0;
        cursor: pointer;

        img {
          display: inline-block;
          vertical-align: middle;
          margin-top: -2px;
        }

        span {
          display: inline-block;
          padding-left: 5px;
          text-transform: uppercase;
          vertical-align: middle;
        }
      }

      .toggle_header {
        display: none !important;
      }

      .toggle_header1 {
        display: block !important;
        width: 100%;
      }

      .language_toggler {
        display: none;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .header-main {
    .header-container {
      height: 26px;

      .logo {
        height: 26px;
        display: inline-block;
        vertical-align: top;
      }
    }
  }
}

@keyframes navbarAnimation {
  from {
    right: -247px;
  }

  to {
    right: 0px;
  }
}
