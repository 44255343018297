.home-header-block {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 72em;
    margin: 200px auto 100px auto;
}

.enkelBackground {
    position: absolute;
    z-index: 4;
    width: 150px;
}

.enkelBackground1 {
    position: absolute;
    z-index: 4;
    width: 197px;
}

.rekrutterBackground {
    position: absolute;
    z-index: 4;
    width: 350px;
    height: 30px;
}

.rekrutterBackground1 {
    position: absolute;
    z-index: 4;
    width: 282px;
    height: 30px;
}

.home-header-content__title {
    h1 {
        font-size: 56px;
        line-height: 76px;
        font-family: 'Avenir Heavy';
        margin: 0;
        padding: 0;
        color: #3A3A4A;
    }
}

.home-header__content {
    max-width: 650px;
    margin-top: 0px;
    margin-right: 4em;

    h3 {
        color: #3c476c;
        font-size: 31px;
        margin-bottom: 25px;
    }

    p {
        font-family: 'Avenir LT Std 55 Roman';
        color: #3A3A4A;
        font-size: 21px;
        line-height: 26px;
        margin-bottom: 50px;
        margin-top: 42px;
    }
}

.home-header__buttons-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 24em;
    height: 3em;

    .registrationBtn {
        color: #ffffff;
        padding: 0;
        border-radius: 11px;
        background-color: #5959BE;
        width: 180px;
        height: 52px;
        line-height: 50px;
        font-size: 18px;
        text-align: center;
        font-family: 'Avenir Medium';
        text-transform: none;

        &:hover {
            background-color: #5959BE;
        }
    }

    .buttons-block__read-more {
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1.5px solid #5959BE;
        background: transparent;
        padding: 0;
        border-radius: 11px;
        width: 180px;
        height: 52px;
        line-height: 50px;
        font-family: 'Avenir Medium';
        text-transform: none;
        font-size: 18px;
        color: #3D486B;

        &:hover {
            background: transparent;
        }

        a {
            display: block;
            font-size: 18px;
            color: #3D486B;
            line-height: 50px;
            font-family: 'Avenir Medium';
            text-transform: none;
        }
    }
}

.home-header__img {
    max-height: 440px;
    margin-left: 0px;
    margin-top: 0px;
}

.testerBg {
    position: absolute;
    width: 100%;
    height: 650px;
}

.home-testerSensor-block {
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    h1 {
        font-family: Arial MT Bold;
        color: #3c476c;
        font-size: 40px;
        margin-top: 30px;
        margin-bottom: 50px;
    }
}

.home-testerSensor__familyImg {
    height: 350px;
    width: 580px;
}

.testerSensor__makeSure-block {
    display: flex;
    justify-content: space-between;
    margin-bottom: 80px;
}

.makeSure-block__form {
    width: 100%;
    margin-top: 45px;

    h3 {
        font-family: Arial MT Bold;
        color: #3c476c;
        font-size: 32px;
        letter-spacing: 0.8;
        margin-bottom: 40px;
    }

    h4 {
        color: #3c476c;
        font-size: 22px;
    }
}

.makeSure-form__checkedString {
    display: flex;
    align-items: center;
    height: 28px;
    margin-bottom: 35px;

    h4 {
        padding: 0;
        margin: 0;
        margin-left: 40px;
    }
}

.checkboxImg {
    height: 40px;
}

.home-howItsWork {
    display: flex;
    background: #f7f7ff;
    align-items: center;
    flex-direction: column;
    margin: 144px auto 85px;

    h2 {
        font-family: 'Avenir LT Std 65 Black';
        color: #3D486B;
        font-size: 37px;
    }
}

.home-newTestRequest {
    position: relative;
}

.newTestRequest__section {
    display: flex;
    align-items: center;
    margin-top: 85px;
    margin-left: 17px;
    color: #3c476c;
    position: relative;
    z-index: 1;
}

.newTestRequest-section__img {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 50px;
    width: 72px;
    height: 82px;
    background: #f7f7ff;

    .clockImageMain {
        width: 72px;
        height: 72px;
        background: #f7f7ff;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.newTestRequest-section__img__inner {
    font-family: 'Avenir LT Std 65 Black';
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    background: #FFBE55;
    color: #ffffff;
    z-index: 20;
    font-size: 26px;
    width: 72px;
    height: 72px;
}

.home-backgroundImgLine {
    position: absolute;
    top: 100px;
    left: 53px;
    height: 75%;
    width: 2px;
    background-color: #FFBE55;
}

.home-footer-wrapper {
    background-color: #EEF2FA;
}

.home-footer-block {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 80px;
    padding-bottom: 90px;
    max-width: 50em;
    margin-right: auto;
    margin-left: auto;

    .footer-info__tryFree {
        position: relative;
        top: 40px;
        padding: 0;
        text-align: center;
        background: #5959BE;
        border-radius: 12px;
        color: #FFFFFF;
        font-size: 18px;
        border: none;
        font-family: 'Avenir Heavy';
        width: 172px;
        height: 52px;
        line-height: 50px;
        display: inline-block;
        text-transform: none;

        &:hover {
            background: #5959BE;
        }
    }
}

.home-footer__info {
    position: relative;
    left: 0px;
}

.home-sustainable-block {
    background-color: #eef2fa;
    padding-top: 108px;
    padding-bottom: 108px;
}

.home-sustainable-inner {
    max-width: 64em;
    margin: 0 auto;
}

.home-sustainable {
    display: flex;
    justify-content: center;
    align-items: center;
}

.home-sustainable-left {
    width: 50em;
    padding-right: 3em;

    h4 {
        color: #3D486B;
        font-size: 36px;
        line-height: 40px;
        font-family: 'Avenir Heavy';
        display: block;
        margin-bottom: 60px;
    }

    p {
        color: rgba(61, 72, 107, 0.8);
        font-size: 16.5px;
        font-weight: 300;
        line-height: 26px;
        font-family: 'Avenir LT Std 45 Book';
        padding: 0;
        margin: 0;
    }
}

.home-sustainable-right {
    width: 50em;
    float: right;

    img {
        max-width: 100%;
        height: auto;
    }
}

.home-logos-block {
    margin-bottom: 100px;

    ul {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;

        li {
            display: flex;
            align-self: center;
            // padding-left: 0px;
            // padding-right: 0px;
            // width: 25%;
            // text-align: center;

            img {
                max-width: 100%;
                height: auto;
                display: inline-block;
                vertical-align: top;
            }


        }
    }
}

.home-logos-inner {
    max-width: 66em;
    margin: 0 auto;
}

.home-video-inner {
    max-width: 64em;
    margin: 0 auto;
}

.home-video {
    display: flex;
    width: 100%;
    height: 550px;
    overflow: hidden;
    border-radius: 21px;
    background-color: #ffffff;
}

.home-data-informed-block {
    padding-top: 100px;
    padding-bottom: 54px;

    h2 {
        text-align: center;
        padding: 0;
        margin: 0 0 63px 0;
        color: #3D486B;
        font-size: 36px;
        line-height: 51px;
        font-family: 'Avenir LT Std 65 Black';

        &.d-md-block {
            display: block;
        }

        &.d-md-none {
            display: none;
        }
    }
}

.home-data-informed-inner {
    max-width: 66em;
    margin: 0 auto;

    ul {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;

        li {
            display: inline-block;
            padding-left: 13px;
            padding-right: 13px;
            width: 33.33%;
        }
    }

    .data-block {
        background-color: #ffffff;
        border-radius: 15px;
        display: flex;
        justify-content: center;
        //align-items: center;
        align-content: center;
        flex-wrap: wrap;
        width: 100%;
        height: 356px;
        text-align: center;
        padding: 13px;
        box-sizing: border-box;

        .image {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 110px;
        }

        .name {
            font-family: 'Avenir LT Std 65 Black';
            display: block;
            width: 100%;
            color: rgba(61, 72, 107, 1);
            font-size: 18px;
            line-height: 25px;
            padding-top: 27px;
            padding-bottom: 27px;
        }

        .text {
            display: block;
            width: 100%;
            color: #3D486B;
            font-size: 15px;
            line-height: 22px;
            font-family: 'Avenir Medium';
            font-weight: 300;
            padding: 0px 6px;
            opacity: 0.8;
        }
    }
}

.getstartedbtn {
    display: block;
    width: 100%;
    text-align: center;
    padding-top: 63px;

    .getstarted {
        display: inline-block;
        width: 183px;
        height: 60px;
        line-height: 58px;
        text-align: center;
        color: #F7F7FF;
        font-size: 18px;
        font-weight: 300;
        font-family: 'Avenir Medium';
        background-color: #5959BE;
        border-radius: 11px;
        text-transform: none;
        padding: 0;

        &:hover {
            background-color: #5959BE;
        }
    }
}

@media only screen and (max-width: 1975px) {
    .mobile-home-header__img {
        display: none;
    }

    .enkelBackground {
        position: absolute;
        z-index: 4;
        width: 160px;
        top: 34px;
    }

    .enkelBackground1 {
        position: absolute;
        z-index: 4;
        width: 197px;
        top: 29px;
    }

    .rekrutterBackground {
        position: absolute;
        z-index: 4;
        width: 350px;
        height: 28px;
        top: 103px;
    }

    .rekrutterBackground1 {
        position: absolute;
        z-index: 4;
        width: 282px;
        height: 28px;
        top: 103px;
    }

    .testerSensor__makeSure-block {
        max-width: 75em;
        width: 75em;
    }

    .home-footer__img {
        position: relative;
        left: 0;
        top: 0;
        width: 300px;
    }

    .home-footer__img.mobile {
        display: none;
    }
}

@media only screen and (max-width: 1775px) {
    .enkelBackground {
        position: absolute;
        z-index: 4;
        width: 160px;
        top: 34px;
    }

    .enkelBackground1 {
        position: absolute;
        z-index: 4;
        width: 197px;
        top: 29px;
    }

    .rekrutterBackground {
        position: absolute;
        z-index: 4;
        width: 350px;
        height: 28px;
        top: 103px;
    }

    .rekrutterBackground1 {
        position: absolute;
        z-index: 4;
        width: 282px;
        height: 28px;
        top: 103px;
    }

    .testerSensor__makeSure-block {
        max-width: 73em;
        width: 73em;
    }
}

@media only screen and (max-width: 1575px) {
    .enkelBackground {
        display: none !important;
    }

    .enkelBackground1 {
        display: none !important;
    }

    .rekrutterBackground {
        position: absolute;
        z-index: 4;
        width: 340px;
        height: 30px;
    }

    .rekrutterBackground1 {
        position: absolute;
        z-index: 4;
        width: 282px;
        height: 30px;
    }

    .home-footer__info {
        h2 {
            font-family: 'Avenir LT Std 65 Black';
            color: #2E2E55;
            font-size: 37px;
            line-height: 50px;
            margin-bottom: 23px;
        }

        h4 {
            font-family: 'Avenir LT Std 45 Book';
            color: rgba(61, 72, 107, 0.8);
            font-size: 16.5px;
            line-height: 26px;
            font-weight: 300;
            width: 70%;
        }
    }

    .testerBg {
        position: absolute;
        width: 100%;
        height: 650px;
    }

    .home-testerSensor-block {
        position: relative;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;

        h1 {
            font-family: Arial MT Bold;
            color: #3c476c;
            font-size: 36px;
            margin-top: 30px;
            margin-bottom: 50px;
        }
    }

    .home-testerSensor__familyImg {
        height: 350px;
        width: 580px;
    }

    .testerSensor__makeSure-block {
        display: flex;
        justify-content: space-between;
        margin-bottom: 80px;
        width: 71em;
        max-width: 71em;
    }

    .makeSure-block__form {
        width: 100%;
        margin-top: 45px;

        h3 {
            font-family: Arial MT Bold;
            color: #3c476c;
            font-size: 28px;
            letter-spacing: 0.8;
            margin-bottom: 40px;
        }

        h4 {
            font-family: "FuturaEF Medium";
            color: #3c476c;
            font-size: 25px;
            font-weight: 100;
        }
    }

    .makeSure-form__checkedString {
        display: flex;
        align-items: center;
        height: 28px;
        margin-bottom: 35px;

        h4 {
            padding: 0;
            margin: 0;
            margin-left: 40px;
        }
    }

    .checkboxImg {
        height: 40px;
    }

    .newTestRequest__texts {
        max-width: 50em;
    }

    .newTestRequest__section {
        h4 {
            font-family: 'Avenir Heavy';
            font-size: 24px;
            font-weight: 600;
            color: #3D486B;
            margin-bottom: 3px;
        }

        h5 {
            font-family: 'Avenir LT Std 45 Book';
            font-size: 16.5px;
            font-weight: 300;
            color: #3D486B;
        }
    }
}

@media only screen and (max-width: 1375px) {
    .enkelBackground {
        position: absolute;
        z-index: 4;
        width: 145px;
    }

    .enkelBackground1 {
        position: absolute;
        z-index: 4;
        width: 177px;
    }

    .rekrutterBackground {
        position: absolute;
        z-index: 4;
        width: 310px;
        height: 30px;
    }

    .rekrutterBackground1 {
        position: absolute;
        z-index: 4;
        width: 252px;
        height: 30px;
    }

    .testerSensor__makeSure-block {
        max-width: 68em;
    }

    .home-testerSensor__familyImg {
        height: 330px;
        width: 560px;
    }

    .makeSure-block__form {
        h3 {
            font-size: 24px;
        }

        h4 {
            font-size: 23px;
        }
    }
}

@media only screen and (max-width: 1175px) {
    .enkelBackground {
        width: 135px;
        height: 27px;
    }

    .enkelBackground1 {
        width: 135px;
        height: 27px;
    }

    .rekrutterBackground {
        width: 290px;
        height: 28px;
    }

    .rekrutterBackground1 {
        width: 290px;
        height: 28px;
    }

    .home-testerSensor-block {
        h1 {
            font-size: 32px;
        }
    }

    .testerSensor__makeSure-block {
        max-width: 60em;
    }

    .home-testerSensor__familyImg {
        height: 280px;
        width: 500px;
    }

    .makeSure-block__form {
        h3 {
            font-size: 23px;
        }

        h4 {
            font-size: 21px;
        }
    }

    .checkboxImg {
        height: 32px;
    }
}

@media only screen and (max-width: 992px) {
    .mobile_tester {
        display: block !important;
    }

    .mobile-home-header__img {
        display: block;
        height: 400px;
        margin: 30px auto 0px auto;
    }

    .enkelBackground {
        width: 135px;
        height: 27px;
        left: 105px;
    }

    .enkelBackground1 {
        width: 135px;
        height: 27px;
        left: 105px;
    }

    .rekrutterBackground {
        width: 290px;
        height: 28px;
        left: 130px;
    }

    .rekrutterBackground1 {
        width: 290px;
        height: 28px;
        left: 130px;
    }

    .home-header__img {
        display: none;
    }

    .testerSensor__makeSure-block {
        display: flex;
        flex-wrap: wrap;
        max-width: 50em;
        text-align: center;
        justify-content: center;
        margin: -10px auto;
    }

    .testerBg {
        height: 820px;
    }

    .home-testerSensor-block {
        h1 {
            margin-top: 20px;
            font-size: 33px;
        }
    }

    .home-header__buttons-block {
        .registrationBtn {
            display: none;
        }

        .buttons-block__read-more {
            background-color: #5959BE;
            border-radius: 8px;
            width: 146px;
            height: 42px;
            border: none;
            color: #FFFFFF;
            font-size: 14px;
            font-weight: 400;

            &:hover {
                background-color: #5959BE;
                color: #FFFFFF;
            }
        }
    }
}

@media only screen and (max-width: 775px) {
    .mobile_tester {
        display: block !important;
    }

    .mobile-home-header__img {
        display: block;
        height: 360px;
        margin: 30px auto 60px auto;
    }

    .enkelBackground {
        width: 480px;
        height: 25px;
        left: 1px;
        top: 68px;
    }

    .enkelBackground1 {
        width: 480px;
        height: 25px;
        left: 1px;
        top: 68px;
    }

    .rekrutterBackground {
        display: none;
    }

    .rekrutterBackground1 {
        display: none;
    }

    .home-testerSensor-block {
        h1 {
            font-size: 30px;
            max-width: 15em;
            text-align: center;
            line-height: 35px;
        }
    }

    .testerSensor__makeSure-block {
        max-width: 35em;
    }

    .home-testerSensor__familyImg {
        height: 300px;
        width: 520px;
        margin-bottom: 15px;
    }

    .checkboxImg {
        height: 32px;
    }

    .makeSure-form__checkedString {
        h4 {
            font-size: 21px;
        }
    }

    .makeSure-block__form {
        margin: 0 auto;
    }
}

@media only screen and (max-width: 575px) {
    .mobile-home-header__img {
        position: relative;
        display: block;
        height: 300px;
    }

    .header-container {
        li {
            margin-left: 0 !important;
        }
    }

    .header3 {
        font-size: 18px !important;
    }

    .header3_en {
        font-size: 16px !important;
    }

    .enkelBackground {
        width: 370px;
        height: 22px;
        left: 1px;
        top: 49px;
    }

    .enkelBackground1 {
        width: 370px;
        height: 22px;
        left: 1px;
        top: 49px;
    }

    .home-testerSensor-block {
        display: none;
    }

    .home-header__buttons-block {
        .registrationBtn {
            margin-right: 15px;
        }
    }
}

@media only screen and (max-width: 375px) {
    .mobile-home-header__img {
        display: block;
        height: 250px;
    }

    .enkelBackground {
        width: 310px;
        height: 18px;
        left: 4px;
        top: 24px;
    }

    .enkelBackground1 {
        width: 310px;
        height: 18px;
        left: 4px;
        top: 24px;
    }

    .home-testerSensor-block {
        h1 {
            font-size: 22px;
            max-width: 14em;
        }
    }

    .makeSure-block__form {
        h3 {
            font-size: 21px;
            font-size: 18px;
        }
    }

    .checkboxImg {
        margin-left: 15px;
    }

    .testerSensor__makeSure-block {
        max-width: 16em;
        text-align: justify;
    }

    .home-testerSensor__familyImg {
        height: 180px;
        width: 300px;
        margin-bottom: 25px;
    }
}

@media only screen and (max-device-width: 375px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
    .mobile-home-header__img {
        display: block;
        height: 200px;
        margin: -8px auto 55px auto;
    }

    .enkelBackground {
        width: 310px;
        height: 18px;
        left: 4px;
        top: 24px;
    }

    .enkelBackground1 {
        width: 310px;
        height: 18px;
        left: 4px;
        top: 24px;
    }
}

@media only screen and (max-width: 1200px) and (min-width: 992px) {
    .home-header-block {
        max-width: 60em;
    }
}

@media only screen and (max-width: 992px) and (min-width: 768px) {
    .home-video-inner {
        max-width: 46em;
    }

    .home-sustainable-inner {
        max-width: 46em;
    }

    .home-footer-block {
        max-width: 46em;
    }

    .footer-wrapper {
        max-width: 46em;
    }

    .home-footer__img.mobile {
        display: none;
    }

    .home-footer__info {
        h4 {
            width: 100%;
        }
    }
}

@media only screen and (max-width: 767px) {
    .home-header-block {
        margin-top: 85px;
        margin-bottom: 40px;
        max-width: 86%;
        display: block;
    }

    .home-header__content {
        max-width: 80%;
        margin-right: 0;

        p {
            font-size: 14px;
            line-height: 19px;
            margin-bottom: 25px;
            margin-top: 15px;
        }
    }

    .home-header-content__title {
        h1 {
            font-size: 24px;
            line-height: 36px;
        }
    }

    .registrationBtn {
        display: none;
    }

    .buttons-block__read-more {
        background-color: #5959BE;
        border-radius: 8px;
        width: 146px;
        height: 42px;
        border: none;

        a {
            color: #FFFFFF;
            font-size: 14px;
            font-weight: 400;
        }
    }

    .home-logos-block {
        display: none;
    }

    .home-video-block {
        display: none;
    }

    .home-sustainable-block {
        display: none;
    }

    .home-data-informed-block {
        max-width: 100%;
        padding-top: 40px;
        padding-bottom: 40px;
        background-color: #EEF2FA;

        h2 {
            color: #3A3A4A;
            font-size: 20px;
            line-height: 28px;
            margin-bottom: 30px;
            font-family: 'Avenir Heavy';

            &.d-md-block {
                display: none;
            }

            &.d-md-none {
                display: block;
            }
        }
    }

    .home-data-informed-inner {
        max-width: 82%;

        ul {
            flex-wrap: wrap;

            li {
                width: 100%;
                padding: 0 0 35px;
            }
        }

        .data-block {
            height: 268px;
            padding: 15px 15px;

            .image {
                height: 80px;

                img {
                    max-height: 80px;
                }
            }

            .name {
                font-size: 14px;
                line-height: 19px;
                padding-top: 20px;
                padding-bottom: 20px;
            }

            .text {
                font-size: 12px;
                line-height: 20px;
            }
        }
    }

    .getstartedbtn {
        display: none;
    }

    .home-howItsWork {
        margin-top: 60px;
        margin-bottom: 40px;

        h2 {
            font-size: 22px;
            line-height: 30px;
            margin-bottom: 10px;
        }
    }

    .newTestRequest__section {
        margin-top: 25px;
        margin-left: 0px;
        margin-right: 0px;

        h4 {
            font-size: 16px;
            line-height: 22px;
            margin-bottom: 4px;
            font-family: 'Avenir LT Std 65 Black';
        }

        h5 {
            font-size: 12px;
            line-height: 16px;
            font-family: 'Avenir LT Std 45 Book';
        }
    }

    .newTestRequest-section__img {
        width: 40px;
        height: 48px;
        margin-right: 21px;

        .clockImageMain {
            width: 40px;
            height: 40px;

            img {
                max-width: 28px;
            }
        }
    }

    .newTestRequest-section__img__inner {
        width: 40px;
        height: 40px;
        line-height: 38px;
        font-size: 12px;
        border-radius: 8px;
    }

    .newTestRequest__texts {
        max-width: 16em;
    }

    .home-backgroundImgLine {
        top: 50px;
        left: 19px;
        width: 1px;
    }

    .home-footer-block {
        display: table;
        padding-top: 40px;
        padding-bottom: 50px;
        max-width: 86%;
        text-align: center;

        .home-footer__info {
            width: 100%;
            text-align: center;
            padding-top: 30px;

            h2 {
                font-size: 22px;
                margin-bottom: 15px;
            }

            h4 {
                width: 100%;
                font-size: 14px;
                line-height: 24px;
                text-align: left;
                margin-bottom: 30px;
            }
        }

        .footer-info__tryFree {
            font-size: 14px;
            width: 146px;
            height: 42px;
            top: 0;
            border-radius: 8px;
            font-weight: 400;
            line-height: 40px;
            font-family: 'Avenir Medium';
        }
    }

    .home-footer__img {
        display: none;
    }

    .home-footer__img.mobile {
        display: inline-block;
        max-width: 100%;
        height: auto;
        width: 160px;
    }


}

@media only screen and (max-width: 420px) {
    .home-header__content {
        max-width: 93%;
    }
}

@media only screen and (max-width: 360px) {
    .home-header__content {
        max-width: 100%;
    }
}

