@font-face {
    font-family: 'Avenir LT Std 45 Book';
    src: url('../fonts/AvenirLTStd-Book.woff2') format('woff2'),
        url('../fonts/AvenirLTStd-Book.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Avenir LT Std 55 Roman';
    src: url('../fonts/AvenirLTStd-Roman.woff2') format('woff2'),
        url('../fonts/AvenirLTStd-Roman.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Avenir LT Std 65 Black';
    src: url('../fonts/AvenirLTStd-Black.woff2') format('woff2'),
        url('../fonts/AvenirLTStd-Black.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Avenir Heavy';
    src: url('../fonts/AvenirHeavy.woff2') format('woff2'),
        url('../fonts/AvenirHeavy.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Avenir Medium';
    src: url('../fonts/AvenirMedium.woff2') format('woff2'),
        url('../fonts/AvenirMedium.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

html { scroll-behavior: smooth; }

.custom-input-container {
    height: 80px;
    position: relative;

    svg {
        color: #6868B4;
        width: 20px;
        height: 20px;
        z-index: 1;
        position: absolute;
        top: 15px;
        left: 12px;
        width: 20px;
    }

    .custom-input {
        width: 412px;
        height: 52px;
        border: 1px solid #6363AD;
        background-color: #ffffff;
        border-radius: 7px;
        padding-left: 48px;
        font-family: 'Avenir LT Std 55 Roman';
        font-size: 15px;
        color: #495057;
        box-sizing: border-box;
    }

    .custom-input::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: rgba(46, 46, 85, 0.6);
    }

    .custom-input::-moz-placeholder {
        /* Firefox 19+ */
        color: rgba(46, 46, 85, 0.6);
    }

    .custom-input:-ms-input-placeholder {
        /* IE 10+ */
        color: rgba(46, 46, 85, 0.6);
    }

    .custom-input:-moz-placeholder {
        /* Firefox 18- */
        color: rgba(46, 46, 85, 0.6);
    }

    .custom-input:focus-visible {
        outline: none;
    }

    .invalid-feedback {
        width: 100%;
        margin-top: .25rem;
        font-size: 80%;
        color: #dc3545;
        font-family: 'Avenir LT Std 55 Roman';
    }
}