.book-demo-block {
    display: flex;
    margin: 193px auto 100px auto;
    max-width: 62em;
    align-items: start;
}

.book-demo-left {
    display: flex;
    flex-wrap: wrap;
    margin-right: 5em;

    h4 {
        display: block;
        width: 100%;
        color: #3D486B;
        font-size: 37px;
        line-height: 51px;
        font-family: 'Avenir LT Std 65 Black';
        margin-bottom: 30px;
    }

    p {
        display: block;
        width: 100%;
        color: #3D486B;
        font-size: 16px;
        line-height: 22px;
        font-family: 'Avenir LT Std 45 Book';
        margin-bottom: 37px;

        a {
            color: #3D486B;
            text-decoration: underline;
        }
    }

    ul {
        display: block;
        width: 100%;
        margin-bottom: 38px;

        li {
            display: block;
            width: 100%;
            color: #3D486B;
            font-size: 16px;
            line-height: 22px;
            font-family: 'Avenir LT Std 45 Book';
            margin-bottom: 10px;

            i {
                display: inline-block;
                width: 15px;
                height: 15px;
                border-radius: 50%;
                text-align: center;
                font-size: 7px;
                color: #ffffff;
                line-height: 15px;
                vertical-align: middle;
                margin-top: -3px;
                margin-right: 5px;
                background-color: #3D486B;
            }
        }
    }
}

.book-demo-right {

    .custom-input {
        padding-left: 22px !important;
    }

    .text-area {
        height: 150px;
        line-height: 30px;
        padding-top: 10px;
        padding-bottom: 10px;
        border: 1px solid #6363AD;
        background-color: #ffffff;
        border-radius: 7px;
        padding-left: 48px;
        margin-bottom: 20px;
        font-family: 'Avenir LT Std 55 Roman';
        font-size: 15px;
        color: #495057;
        box-sizing: border-box;
        padding-left: 22px;
        width: 412px;

        &::-webkit-input-placeholder {
            /* Chrome/Opera/Safari */
            color: rgba(46, 46, 85, 0.6);
        }
    
        &::-moz-placeholder {
            /* Firefox 19+ */
            color: rgba(46, 46, 85, 0.6);
        }
    
        &:-ms-input-placeholder {
            /* IE 10+ */
            color: rgba(46, 46, 85, 0.6);
        }
    
        &:-moz-placeholder {
            /* Firefox 18- */
            color: rgba(46, 46, 85, 0.6);
        }
    
        &:focus-visible {
            outline: none;
        }
    }
}

@media only screen and (max-width: 767px) {
    .book-demo-block {
        margin: 90px auto 82px auto;
        max-width: 86%;
        display: block;
    }

    .book-demo-left {
        width: 100%;
        display: block;

        p {
            display: none;
        }

        h4 {
            font-size: 20px;
            line-height: 27px;
            margin-bottom: 25px;
        }

        ul {
            li {
                font-size: 11px;
                line-height: 12px;

                i {
                    font-size: 8px;
                    width: 10px;
                    height: 10px;
                    line-height: 10px;
                }
            }

            margin-bottom: 45px;
        }
    }

    .book-demo-right {
        width: 100%;
        display: block;
    }
}

@media only screen and (max-width: 992px) {
    .book-demo-block {
        margin: 90px auto 82px auto;
        max-width: 86%;
        display: block;
    }

    .book-demo-left {
        width: 100%;
        display: block;
    }

    .book-demo-right {
        width: 100%;
        display: block;
    }

    .custom-input {
        width: 100% !important;
    }

    .text-area {
        width: 100% !important;
    }

    .bookdemo-button {
        width: 100% !important;
    }
}