.footer-container {
  display: block;
  padding-top: 44px;
  padding-bottom: 40px;
  height: 24px;
  background-color: #3c476c;

  .footer-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: transparent;
    padding: 0;
    margin: 0 auto;
    max-width: 50em;

    .link-block {
      display: flex;
      padding: 0;
      margin: 0;
      color: #fff;

      li {
        font-family: 'Avenir Medium';
        font-weight: normal;
        margin-right: 34px;
        font-size: 16.5px;

        a {
          color: #ffffff;
        }
      }
    }

    .created-at-block {
      font-family: 'Avenir LT Std 45 Book';
      font-weight: normal;
      margin-right: 0px;
      font-size: 16px;
      color: #fff;
    }
  }
}

@media only screen and (max-width: 992px) and (min-width: 768px) {
  .footer-container {
    .footer-wrapper {
      max-width: 46em;
    }
  }
}

@media only screen and (max-width: 767px) {
  .footer-container {
    height: auto;
    padding-top: 15px;
    padding-bottom: 15px;

    .footer-wrapper {
      width: 86%;
      padding: 0 15px;

      .link-block {
        flex-wrap: wrap;

        li {
          width: 100%;
          font-size: 12px;
          margin-right: 0;
          margin-bottom: 10px;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      .created-at-block {
        display: none;
      }
    }
  }
}