html{
    -webkit-text-size-adjust: 100%;
}

body {
    font-family: 'Avenir LT Std 45 Book';
    margin: 0;
    padding: 0;
    background-color: #f7f7ff;
    -webkit-font-smoothing: subpixel-antialiased;
}

img {
    image-rendering: -webkit-optimize-contrast;
    max-width: 100%;
    height: auto;
}

a {
    text-decoration: none;
}

h1,
h2,
h3,
h4,
h5 {
    margin: 0;
    padding: 0;
}

ul {
    padding: 0;
    margin: 0;
}

li {
    margin: 0;
    padding: 0;
    list-style: none;
}

p {
    margin: 0;
    padding: 0;
}

.App {
    position: absolute;
    z-index: 1;
    background: #f7f7ff 0% 0%;
    width: 100%;
    height: 100%;
}


p.loading {
    color: white;
    font-size: 40px;
    text-align: center;
    margin-left: -20px;
}


/* loading dots */

.loading:after {
    content: ' .';
    animation: dots 1s steps(5, end) infinite;
}


@keyframes dots {

    0%,
    20% {
        color: rgba(0, 0, 0, 0);
        text-shadow:
            .25em 0 0 rgba(0, 0, 0, 0),
            .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
        color: white;
        text-shadow:
            .25em 0 0 rgba(0, 0, 0, 0),
            .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
        text-shadow:
            .25em 0 0 white,
            .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
        text-shadow:
            .25em 0 0 white,
            .5em 0 0 white;
    }
}

@import 'common';
@import 'header';
@import 'footer';
@import 'home';
@import 'bookdemo';
@import 'registration';
@import 'tester';
@import 'blog';